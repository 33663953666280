@import './variables-mixins/variables-mixins';

.react-slideshow-container + div.indicators > div {
    width: 9px;
    height: 9px;
    margin: 0 5px 10px;
    border-radius: 50%;
    background: #cec3a9d6;
    cursor: pointer;

    @include breakpoint($breakpointM) {
        width: 15px;
        height: 15px;
        margin: 0 10px 10px;
    }
}

.react-slideshow-container + div.indicators > div.active {
  background: #7c6f3a;
}