@import '../../../scss/variables-mixins/variables-mixins';

.sectionWrapper {
  padding-top: 100px;
  padding-bottom: 100px;

  > p {
    font-size: 13px;
    letter-spacing: .5px;
    margin: 0 20px;
    text-align: left;
    margin-bottom: 5px;
  }

  > h2, h3 {
    font-size: 13px;
    letter-spacing: 1px;
    margin: 20px;
  }
}
