
  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  
  html {
    font-size: 100%;
    font-family: 'Open Sans', sans-serif;
  }
  
  body {
    box-sizing: border-box;
  }
  
  a,
  a:visited {
    color: inherit;
    // text-decoration: none;
  }
  
  a:hover {
    //color: #ce9e50;
    // color: #ddcaaa;
    // color: #f2d8ae;
    // color: #a36503;
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
  }
